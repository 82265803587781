import React from "react";
import "../sass/pages/plan.scss";
import basicImg from "../assets/essencial.webp"; 
import advancedImg from "../assets/premium.webp";
import customImg from "../assets/personalizado.webp";

const plans = [
  {
    id: 1,
    name: "Plano Essencial",
    price: "R$ 1.500,00",
    features: [
      "✅ Site institucional básico",
      "✅ Responsivo e otimizado",
      "✅ Formulário de contato",
    ],
    payment: "💳 50% na aprovação + 50% na entrega ou em até 12x",
    image: basicImg,
    link: "https://pay.kiwify.com.br/qOkRskN",
  },
  {
    id: 2,
    name: "Plano Avançado",
    price: "R$ 3.000,00",
    features: [
      "✅ Site completo (até 6 páginas)",
      "✅ SEO básico",
      "✅ Integração com redes sociais",
    ],
    payment: "💳 50% na aprovação + 50% na entrega ou em até 12x",
    image: advancedImg,
    link: "https://pay.kiwify.com.br/8gpMK9f",
  },
  {
    id: 4,
    name: "Plano Personalizado",
    price: "Sob Consulta",
    features: [
      "📌 Projeto sob demanda, funcionalidades exclusivas",
      "📌 Estudo de UX/UI customizado",
    ],
    payment: "💳 Condições flexíveis conforme projeto",
    image: customImg,
    link: "#contact", // Direciona para a seção de contato
  },
];

const Plan = () => {
  return (
    <section className="plans-section" id="plan">
      <h2>Nossos Planos</h2>
      <p className="plans-subtext">
        Escolha o plano ideal para o seu negócio! Todos os planos incluem suporte
        especializado e otimização para garantir o melhor desempenho.
      </p>
      
      <div className="plans-container">
        {plans.map((plan) => (
          <div key={plan.id} className="plan-card">
            <img src={plan.image} alt={plan.name} className="plan-image" />
            <h3>{plan.name}</h3>
            <p className="plan-price">{plan.price}</p>
            <ul className="plan-features">
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <p className="plan-payment">{plan.payment}</p>
            <a 
              href={plan.link} 
              className="select-plan-btn" 
              target={plan.id === 4 ? "_self" : "_blank"} // Abre os links da Kiwify em nova aba, mas o plano personalizado na mesma página
              rel="noopener noreferrer"
            >
              Escolher Plano
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Plan;
