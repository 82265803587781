import React from "react";
import { motion } from "framer-motion";
import "../sass/pages/features.scss";
import { FaLaptopCode, FaChartLine, FaPalette, FaMobileAlt, FaCogs, FaShieldAlt } from "react-icons/fa";

const Features = () => {
  return (
    <section id="features" className="features">
      <motion.h2 
        className="section-title"
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Nossos Serviços
      </motion.h2>

      <motion.p 
        className="section-subtitle"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.3 }}
      >
        Soluções completas para transformar sua presença digital
      </motion.p>
      
      <div className="feature-grid">
        {[
          { icon: <FaLaptopCode />, title: "Desenvolvimento Web", desc: "Sites modernos, responsivos e de alta performance para sua empresa." },
          { icon: <FaChartLine />, title: "SEO e Performance", desc: "Otimização para mecanismos de busca e melhor experiência para o usuário." },
          { icon: <FaPalette />, title: "Design UI/UX", desc: "Criação de interfaces elegantes e intuitivas para potencializar sua marca." },
          { icon: <FaMobileAlt />, title: "Aplicações Mobile", desc: "Apps inovadores para Android e iOS, desenvolvidos sob medida." },
          { icon: <FaCogs />, title: "Software Personalizado", desc: "Soluções digitais sob medida para atender às necessidades do seu negócio." },
          { icon: <FaShieldAlt />, title: "Segurança Digital", desc: "Implementação de medidas avançadas para proteção de dados e privacidade." }
        ].map((feature, index) => (
          <motion.div 
            key={index} 
            className="feature-card"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className="feature-icon">{feature.icon}</div>
            <h3>{feature.title}</h3>
            <p>{feature.desc}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Features;
