import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "../sass/pages/whatsapp.scss"; // Arquivo de estilos para o botão

const WhatsAppButton = () => {
  const phoneNumber = "5511975782527"; // Substitua pelo número correto
  const whatsappUrl = `https://wa.me/${phoneNumber}`;

  return (
    <a href={whatsappUrl} className="whatsapp-button" target="_blank" rel="noopener noreferrer">
      <FaWhatsapp className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppButton;
