//import React, { useState, useEffect } from "react";
import Navbar from "./components/navbar";
import Hero from "./components/hero";
import Features from "./components/features";
import Plan from "./components/plans";
import About from "./components/about";
import Portfolio from "./components/portifolio";
import Contact from "./components/contact";
import Footer from "./components/footer";
import WhatsAppButton from "./components/whatsappButton";
import ThemeToggle from "./components/ThemeToggle";
import { ThemeProvider } from "./context/themeContext";

const App = () => {
  return (
    <ThemeProvider>
      <div className="app">
        <Navbar />
        <ThemeToggle />
        <Hero />
        <About />
        <Features />
        <Plan />
        <Portfolio />
        <Contact />
        <Footer />
        <WhatsAppButton />
      </div>
    </ThemeProvider>
  );
};

export default App;
