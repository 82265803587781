import React, { useState, useEffect, useContext } from "react";
import { FaBars, FaTimes, FaSun, FaMoon } from "react-icons/fa";
import { ThemeContext } from "../context/themeContext"; 
import LogoLight from "../assets/3.png"; 
import LogoDark from "../assets/4.png"; 
import "../sass/global/navbar.scss"; 

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [navBackground, setNavBackground] = useState(false);
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    const handleScroll = () => {
      setNavBackground(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [menuOpen]);

  return (
    <nav className={`navbar ${navBackground ? "scrolled" : ""}`}>
      <div className="nav-container">
        {/* LOGO */}
        <a href="#home" className="logo">
          <img src={theme === "dark" ? LogoDark : LogoLight} alt="Logo" />
        </a>

        {/* MENU DESKTOP */}
        <ul className="nav-links">
          <li><a href="#hero">Início</a></li>
          <li><a href="#about">Sobre Nós</a></li>
          <li><a href="#features">Serviços</a></li>
          <li><a href="#plan">Planos</a></li>
          <li><a href="#portfolio">Modelos</a></li> 
          <li><a href="#contact">Contato</a></li>
        </ul>

        {/* BOTÃO DE CONTATO */}
        <a href="#contact" className="contact-btn">Fale Conosco</a>

        {/* SWITCH DE TEMA */}
        <button onClick={toggleTheme} className="theme-switch">
          {theme === "dark" ? <FaSun className="sun-icon" /> : <FaMoon className="moon-icon" />}
        </button>

        {/* MENU HAMBURGUER (APARECE EM TELAS < 950px) */}
        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>

        {/* MENU LATERAL MOBILE (AGORA ABRE NA ESQUERDA) */}
        <div className={`mobile-menu ${menuOpen ? "open" : ""}`}>
          <button className="close-menu" onClick={() => setMenuOpen(false)}>✕</button>
          <ul>
            <li><a href="#hero" onClick={() => setMenuOpen(false)}>Início</a></li>
            <li><a href="#about" onClick={() => setMenuOpen(false)}>Sobre Nós</a></li>
            <li><a href="#features" onClick={() => setMenuOpen(false)}>Serviços</a></li>
            <li><a href="#plan" onClick={() => setMenuOpen(false)}>Planos</a></li>
            <li><a href="#portfolio" onClick={() => setMenuOpen(false)}>Modelos</a></li>
            <li><a href="#contact" onClick={() => setMenuOpen(false)}>Contato</a></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
