import React, { useContext } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "../context/themeContext"; // Importando o contexto de tema
import "../sass/pages/about.scss";
import aboutImageDark from "../assets/4.png";  // Imagem para tema escuro
import aboutImageLight from "../assets/3.png"; // Imagem para tema claro


const About = () => {
  const { theme } = useContext(ThemeContext); // Obtém o tema atual
  const aboutImage = theme === "dark" ? aboutImageDark : aboutImageLight; // Define a imagem conforme o tema

  return (
    <section id="about" className="about">
      <motion.div 
        className="about-container"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.div 
          className="about-text"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <h2>Sobre Nós</h2>
          <p>
            Somos especialistas em criar soluções digitais modernas e personalizadas.
            Nosso compromisso é entregar sites otimizados, responsivos e que impulsionam 
            o sucesso dos nossos clientes no mundo digital.
          </p>
          <p>
            Nossa missão é transformar ideias em projetos inovadores, com foco em design,
            tecnologia e experiência do usuário.
          </p>
          <p>
            Junte-se a nós e fortaleça sua presença digital!
          </p>

          <motion.a 
            href="#contact" 
            className="about-button"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Entre em Contato
          </motion.a>
        </motion.div>

        <motion.div 
          className="about-image"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          <img src={aboutImage} alt="Nossa Equipe" />
        </motion.div>
      </motion.div>
    </section>
  );
};

export default About;

