import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import "../sass/pages/hero.scss";
import { FaCheckCircle } from "react-icons/fa";
import HeroImage from "../assets/Sites e Softwares Otimizados.png";

const Hero = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    console.log("Tentando enviar orçamento com e-mail:", email);
    if (!email.trim()) {
      setStatus({ type: "error", message: "Por favor, insira um e-mail válido." });
      return;
    }

    setLoading(true);
    setStatus(null);

    try {
      const response = await axios.post(
        "https://server-email-production.up.railway.app/solicitar-orcamento",
        { email }
      );
      console.log("Resposta do servidor:", response.data);
      setStatus({ type: "success", message: response.data.success });
      setEmail("");
    } catch (error) {
      console.error("Erro ao enviar solicitação:", error);
      setStatus({ type: "error", message: "Erro ao solicitar orçamento." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="hero" className="hero">
      <motion.div 
        className="hero-content"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.h1 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          Desenvolvimento Digital Sob Medida
        </motion.h1>
        
        <motion.p 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.2 }}
        >
          Sites modernos e softwares personalizados para levar sua empresa ao próximo nível.
        </motion.p>

        <div className="hero-highlights">
          {["Websites profissionais e otimizados", "Sistemas personalizados para seu negócio", "Experiência e inovação em cada projeto"].map((text, index) => (
            <motion.div 
              key={index} 
              className="highlight"
              initial={{ opacity: 0, x: -30 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: index * 0.3 }}
            >
              <FaCheckCircle className="icon" />
              <span>{text}</span>
            </motion.div>
          ))}
        </div>

        <motion.div 
          className="hero-cta"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <input 
            type="email" 
            placeholder="Digite seu e-mail" 
            className="email-input" 
            value={email}
            onChange={handleChange}
          />
          <button className="cta-button" onClick={handleSubmit} disabled={loading}>
            {loading ? "Enviando..." : "Solicitar Orçamento"}
          </button>
        </motion.div>

        {status && <p className={`status-message ${status.type}`}>{status.message}</p>}

        <motion.p 
          className="small-text"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.2, delay: 1.5 }}
        >
          ✔ Sem compromisso. Retorno rápido!
        </motion.p>
      </motion.div>

      <motion.div 
        className="hero-image"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.2, delay: 0.5 }}
      >
        <img src={HeroImage} alt="Desenvolvimento Digital" />
      </motion.div>
    </section>
  );
};

export default Hero;