import React from "react";
import "../sass/pages/portifolio.scss";
import corporativo from "../assets/corporativo.png";
import lp from "../assets/lp-minimalist.png";
import personalizado from "../assets/personalizado.png";
import blog from "../assets/blog.png";

const Portfolio = () => {
  const modelos = [
    {
      titulo: "Landing Page Minimalista",
      descricao: "Perfeito para campanhas e capturas de leads\ncom design impactante.",
      imagem: lp,
    },
    {
      titulo: "Blog Profissional",
      descricao: "Plataforma ideal para compartilhar conteúdos\n e engajar seu público.",
      imagem: blog,
    },
    {
      titulo: "Modelo Corporativo",
      descricao: "Ideal para empresas que buscam\numa presença profissional online.",
      imagem: corporativo,
    },
    {
      titulo: "Projeto Personalizado",
      descricao: "Desenvolvemos soluções sob medida\npara o seu negócio.\nDesde sites institucionais\naté sistemas completos e\nsoftwares personalizados.",
      imagem: personalizado,
    },
  ];

  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <h2>Modelos Personalizados para Você</h2>
        <p className="portfolio-subtext">
          Os valores abaixo são referências! Se precisar de algo diferente, criamos um site ou sistema totalmente exclusivo para você.
        </p>

        {/* ✅ Grid de Cards */}
        <div className="portfolio-container">
          {modelos.map((modelo, index) => (
            <div key={index} className="portfolio-card">
              <img src={modelo.imagem} alt={modelo.titulo} className="portfolio-image" />
              <h3>{modelo.titulo}</h3>
              <p>{modelo.descricao.split("\n").map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              ))}</p>
            </div>
          ))}
        </div>

        <div className="portfolio-cta">
          <a href="#contact" className="cta-button">
            Fale Conosco e Desenvolva seu Projeto Único!
          </a>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
