import React, { useContext } from "react";
import "../sass/global/footer.scss";
import { ThemeContext } from "../context/themeContext"; // Importando o contexto de tema
import LogoDark from "../assets/4.png";  // Logo para o tema escuro
import LogoLight from "../assets/3.png"; // Logo para o tema claro

const Footer = () => {
  const { theme } = useContext(ThemeContext); // Obtém o tema atual

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* LOGO E SLOGAN */}
        <div className="footer-brand">
          <img 
            src={theme === "dark" ? LogoDark : LogoLight} 
            alt="StarDevs" 
            className="footer-logo" 
          />
        </div>

        {/* LINKS */}
        <div className="footer-links">
          <div className="footer-column">
            <h4>Serviços</h4>
            <ul>
              <li><a href="#portfolio">Modelos</a></li>
              <li><a href="#plan">Planos</a></li>
              <li><a href="#contact">Contato</a></li>
            </ul>
          </div>

          <div className="footer-column">
            <h4>Empresa</h4>
            <ul>
              <li><a href="#about">Sobre Nós</a></li>
             
             
            </ul>
          </div>

          <div className="footer-column">
            <h4>Suporte</h4>
            <ul>
              
              <li><a href="#contact">Atendimento</a></li>
              
            </ul>
          </div>
        </div>
      </div>

      {/* COPYRIGHT */}
      <div className="footer-bottom">
        <p>&copy; 2024 StarDevs. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
