import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import "../sass/pages/contact.scss";
import { FaUser, FaEnvelope, FaCommentDots, FaPaperPlane, FaWhatsapp } from "react-icons/fa";

const Contact = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const API_URL = "https://server-email-production.up.railway.app"; // URL do servidor no Railway

  // Atualiza os inputs
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Envia o formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus(null);
    console.log("Enviando dados:", formData);

    try {
      const response = await axios.post(`${API_URL}/send-email`, formData);
      console.log("Resposta da API:", response.data);
      setStatus({ type: "success", message: response.data.success });
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Erro no envio do formulário:", error);
      setStatus({ type: "error", message: "Erro ao enviar mensagem." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.section id="contact" className="contact">
      <motion.div className="contact-container">
        <motion.div className="contact-info">
          <h2>Fale Conosco</h2>
          <p>Estamos prontos para transformar suas ideias em realidade!</p>
          <div className="contact-detail">
            <FaEnvelope className="icon" />
            <span>comercial@stardevs.com.br</span>
          </div>
          <div className="contact-detail">
            <FaWhatsapp className="icon" />
            <span>(11)97578-2527</span>
          </div>
        </motion.div>

        <motion.form className="contact-form" onSubmit={handleSubmit}>
          <h3>Conte-nos mais sobre seu projeto!</h3>

          <div className="input-group">
            <FaUser className="input-icon" />
            <input
              type="text"
              name="name"
              placeholder="Seu Nome"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-group">
            <FaEnvelope className="input-icon" />
            <input
              type="email"
              name="email"
              placeholder="Seu E-mail"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-group">
            <FaCommentDots className="input-icon" />
            <textarea
              name="message"
              placeholder="Nos conte mais sobre sua ideia..."
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          {status && <p className={status.type}>{status.message}</p>}

          <motion.button type="submit" className="send-button" disabled={loading}>
            <FaPaperPlane /> {loading ? "Enviando..." : "Vamos Conversar!"}
          </motion.button>
        </motion.form>
      </motion.div>
    </motion.section>
  );
};

export default Contact;
