import React, { useContext } from "react";
import { ThemeContext } from "../context/themeContext";
import { FaSun, FaMoon } from "react-icons/fa";
import "../sass/pages/themeToggle.scss"; // Arquivo de estilos para o switch

const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <div className="theme-toggle" onClick={toggleTheme}>
      {theme === "dark" ? <FaSun className="icon" /> : <FaMoon className="icon" />}
    </div>
  );
};

export default ThemeToggle;
